<template>
  <v-row>
    <v-col class="ma-0 pa-0">
      <iframe
        :src="loadURL(url)"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        style="max-width: 100%;"
      ></iframe>
    </v-col>
  </v-row>
</template>  
  
  <script>

export default {

  name: "Youtube",
  props: {
    url: { type: String, required: true },
  },
  data: () => ({
  }),

  mounted: function () {
  },

  methods: {
    loadURL(raw_url) {
      const youtubeEmbedTemplate = "https://www.youtube.com/embed/";
      const regex = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
      const YId = regex.exec(raw_url)[3];

      const topOfQueue = youtubeEmbedTemplate.concat(YId);

      return topOfQueue;
    },

  }
};
  </script>