<template>
  <v-container class="ma-0 pa-0">
    <v-row
      no-gutters
      justify="center"
    >
      <v-col
        class="ma-0 pa-0"
        align="center"
        align-self="center"
      >
        <v-btn
          small
          :plain="show"
          @click="show = !show"
        >
          Pridávam nejaké materiály</v-btn>
      </v-col>
    </v-row>
    <div v-show="show">
      <div v-show="getAttachmentsType('TEXT').length > 0">
        <v-row class="py-1">
          <v-col
            cols="12"
            align="left"
          > Odkazy:
          </v-col>
        </v-row>
        <v-row
          no-gutters
          v-for="item in getAttachmentsType('TEXT')"
          v-bind:key="item.id"
        >
          <v-col class="ma-0 pa-0">
            <poll-discussion-source-text :item="item"></poll-discussion-source-text>
          </v-col>
        </v-row>
      </div>

      <div v-show="getAttachmentsType('IMAGE').length > 0">
        <v-row class="pt-5 pb-1">
          <v-col
            cols="12"
            align="left"
          > Obrázky:
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            cols="12"
            md="4"
            v-for="item in getAttachmentsType('IMAGE')"
            v-bind:key="item.id"
          >

            <poll-discussion-source-image :item="item"></poll-discussion-source-image>
          </v-col>
        </v-row>
      </div>

      <div v-show="getAttachmentsType('VIDEO').length > 0">
        <v-row class="pt-3 pb-4">
          <v-col
            cols="12"
            align="left"
          > Videá:
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            cols="12"
            md="4"
            v-for="item in getAttachmentsType('VIDEO')"
            v-bind:key="item.id"
          >

            <poll-discussion-source-video :item="item"></poll-discussion-source-video>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>
    
    
    <script>
import PollDiscussionSourceText from './PollDiscussionSourceText.vue';
import PollDiscussionSourceImage from './PollDiscussionSourceImage.vue';
import PollDiscussionSourceVideo from './PollDiscussionSourceVideo.vue';


export default {

  name: "Sources",
  props: {
    attachments: { type: Array, required: true },
  },

  components: {
    PollDiscussionSourceText,
    PollDiscussionSourceImage,
    PollDiscussionSourceVideo
  },

  data: () => ({
    show: false,
  }),

  mounted: function () {
  },

  methods: {
    getAttachmentsType(type) {
      return this.attachments.filter(function (obj) {
        return obj.type == type && !obj.deleted;
      });
    }
  }
};
    </script>