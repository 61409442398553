<template>
  <div>
    <v-row
      dense
      class="pb-0 pt-8 mt-0"
    >
      <v-col cols="12">
        <strong>{{ item.title }}</strong> </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col
        cols="12"
        class="px-3"
      >
        <v-img
          :src="item.image"
          style="cursor: pointer;"
          @click="overlay = true"
        ></v-img>
      </v-col>
    </v-row>

    <v-overlay v-show="overlay">
      <v-img
        :src="item.image"
        contain
        style="cursor: pointer;"
        @click="overlay = false"
      ></v-img>
    </v-overlay>
  </div>
</template>
        
  <script>
import Vue from "vue";

Vue.use(require("vue-moment"));

export default {
  props: {
    item: { type: Object, required: true },
  },

  data() {
    return {
      overlay: false
    }
  },

  events: {},

  watch: {
  },

  computed: {
  },

  mounted: function () {
  },

  methods: {
  },
};
  </script>