<template>
  <div>
    <v-row dense>
      <v-col
        cols="12"
        md="8"
      >
        <v-text-field
          label="Vyhľadať"
          v-model="search"
          outlined
          dense
        ></v-text-field>
      </v-col>

      <v-col
        cols="12"
        md="4"
        align="right"
      >
        <v-btn
          text
          color="primary"
          @click="changeSort"
        >
          Zoradiť podľa hodnotenia <div v-show="sort != null">
            <v-icon v-if="sort=='-hodnotenie'">mdi-arrow-down</v-icon><v-icon v-else-if="sort=='hodnotenie'">mdi-arrow-up</v-icon>
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <poll-discussion-type-comment
      v-for="comment in comments"
      :depth="2"
      v-bind:key="comment.id"
      class="my-5"
      :comment="comment"
      :title="title"
      :type="type"
      :questionID="questionID"
      :surveyID="surveyID"
      :partyID="partyID"
      :sync="sync"
      :question="question"
      :createCommentFunc="createCommentFunc"
      :getCommentsFunc="getCommentsFunc"
      :updateCommentFunc="updateCommentFunc"
      :deleteCommentFunc="deleteCommentFunc"
      :voteUpCommentFunc="voteUpCommentFunc"
      :voteDownCommentFunc="voteDownCommentFunc"
      :readCommentFunc="readCommentFunc"
      :createCommentAttachmentFormDataFunc="createCommentAttachmentFormDataFunc"
      :createCommentAttachmentFunc="createCommentAttachmentFunc"
      :updateCommentAttachmentFormDataFunc="updateCommentAttachmentFormDataFunc"
      :updateCommentAttachmentFunc="updateCommentAttachmentFunc"
      :deleteCommentAttachmentFunc="deleteCommentAttachmentFunc"
      @sync-child="syncComments"
    >
    </poll-discussion-type-comment>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PollDiscussionTypeComment from './PollDiscussionTypeComment.vue';

export default {
  name: 'DiscussionType',
  inheritAttrs: true,

  components: {
    PollDiscussionTypeComment
  },

  props: {
    title: { type: String, required: true },
    type: { type: String, required: true },
    questionID: { type: Number, required: false },
    surveyID: { type: Number, required: false },
    partyID: { type: Number, required: false },
    sync: { type: Boolean, required: false, default: false },
    question: { type: Object, required: false },
    createCommentFunc: { type: Function, requred: true },
    getCommentsFunc: { type: Function, required: true },
    updateCommentFunc: { type: Function, required: true },
    deleteCommentFunc: { type: Function, required: true },
    voteUpCommentFunc: { type: Function, required: true },
    voteDownCommentFunc: { type: Function, required: true },
    readCommentFunc: { type: Function, required: true },
    createCommentAttachmentFormDataFunc: { type: Function, required: true },
    createCommentAttachmentFunc: { type: Function, required: true },
    updateCommentAttachmentFormDataFunc: { type: Function, required: true },
    updateCommentAttachmentFunc: { type: Function, required: true },
    deleteCommentAttachmentFunc: { type: Function, required: true },
  },

  data() {
    return {
      timeout: null,
      comments: [],
      count: null,
      sort: '-created_at',
      search: '',
    }
  },

  computed: {
    ...mapGetters("auth", [
      "isAuthenticated",
      "username",
      "isVerified",
      "isVerifiedBank",
      "isVerifiedSmsEmail",
      "isStaff",
      "getMemberStatus",
      "firstName",
      "lastName",
      "gender",
      "user_id"
    ]),

    ...mapGetters("settings", ["getSettings"]),

    getMobileClasses() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "";
      } else {
        return "pl-5";
      }
    },
  },


  events: {},

  watch: {
    sync() {
      this.getComments();
    },
    search() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.getComments();
      }, 500);
    },

    sort() {
      this.getComments();
    },
  },

  mounted: function () {
    this.getComments();
  },

  methods: {
    syncComments() {
      this.$emit('sync-child');
      this.getComments();
    },

    changeSort() {
      if (this.sort == "-created_at")
        this.sort = 'hodnotenie';
      else if (this.sort == "hodnotenie")
        this.sort = '-hodnotenie';
      else if (this.sort == "-hodnotenie")
        this.sort = "-created_at";
    },

    getComments() {
      let limit = 0;
      let offset = 0;
      let filters = [];
      if (this.questionID)
        filters = [['parent', this.questionID], ['type', this.type], ['reaction_to__isnull', true]];
      else
        filters = [['parent_survey', this.surveyID], ['parent_party', this.partyID], ['type', this.type], ['reaction_to__isnull', true]];

      this.getCommentsFunc(this.sort, this.search, limit, offset, filters).then((response) => {
        this.comments = response.results;
        this.count = response.count;
      });
    },
  },
};
</script>