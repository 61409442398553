<template>
  <div>
    <v-row dense>
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          label="Názov"
          v-model="item.title"
        ></v-text-field>

      </v-col>

      <v-col md="2" cols="4">
        <v-text-field
          label="Poradie"
          v-model="item.order"
          type="number"
        ></v-text-field>
      </v-col>


      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          label="Odkaz (url)"
          v-model="item.text"
        ></v-text-field> </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col align="right">
        <v-btn
          color="error"
          class="mr-5"
          small
          @click="remove()"
        >Zmazať</v-btn>

        <v-btn
          v-if="!create"
          color="primary"
          small
          @click="save()"
        >Uložiť</v-btn>
      </v-col>
    </v-row>
  </div>
</template>
        
  <script>
import Vue from "vue";

Vue.use(require("vue-moment"));

export default {
  props: {
    item: { type: Object, required: true },
    create: { type: Boolean, required: false, default: false },
    deleteCommentAttachmentFunc: {type: Function, required: true},
    updateCommentAttachmentFunc: {type: Function, required: true},
    createCommentAttachmentFunc: {type: Function, required: true},
  },

  data() {
    return {
    }
  },

  events: {},

  watch: {
  },

  computed: {
  },

  mounted: function () {
  },

  methods: {
    remove() {
      if (!this.create) {
        this.deleteCommentAttachmentFunc(this.item.id).then(() => {
          this.$emit("sync-child");
          this.item.deleted = true;
        });
      } else {
        this.item.deleted = true;
      }
    },

    save() {
      if (this.item.id) {
        this.updateCommentAttachmentFunc(this.item).then(response => {
          this.$emit("sync-child");
        });
      }
      else {
        this.createCommentAttachmentFunc(this.item).then(response => {
          this.item.id = response.id
          this.$emit("sync-child");
        });
      }
    }

  },
};
  </script>