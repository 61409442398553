<template>
  <v-row no-gutters>
    <v-col
      cols="12"
      md="3"
      align="left"
    >
      <strong>{{ item.title }}</strong>
    </v-col>
    <v-col
      cols="12"
      md="8"
      align="left"
    >
    <a :href="item.text" target="_blank" rel="noopener noreferrer">
      {{ item.text }}
    </a>
    </v-col>
  </v-row>
</template>
        
  <script>
import Vue from "vue";

Vue.use(require("vue-moment"));

export default {
  props: {
    item: { type: Object, required: true },
  },

  data() {
    return {
    }
  },

  events: {},

  watch: {
  },

  computed: {
  },

  mounted: function () {
  },

  methods: {
  },
};
  </script>